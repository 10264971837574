var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-hero-block u-text-white c-hero-block__no-overflow u-flex u-flex-align-center c-hero-block__no-min-height c-hero-block__overlay-to-top"},[_c('Picture',{staticClass:"c-hero-block__background",attrs:{"src":_vm.model.backgroundImage,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.backgroundImageAltText,"mobile":[375, 300],"tablet":[768, 300],"desktop":[992, 600],"wide":[1920, 600],"options":{ mode: 'crop'}}}),_c('div',{staticClass:"o-content-box c-hero-block__content u-flex u-flex-direction-column u-flex-align-center u-width-100"},[_c('h1',{staticClass:"u-flex u-flex-direction-column u-font-size-h2 u-text-align--center"},[_c('span',{staticClass:"c-label--l u-text-no-letter-spacing u-mb--xxs"},[_vm._v(" "+_vm._s(_vm.model.kicker)+" ")]),_c('span',{staticClass:"u-font-alternative"},[_vm._v(" "+_vm._s(_vm.model.heading)+" ")])]),_c('div',{staticClass:"u-flex u-text-black",class:{
        'u-flex-direction-column': _vm.$mq != 'desktop'
      }},[_c('Dropdown',{staticClass:"c-farm-booking__dropdown ",class:{
          'u-mr--m': _vm.$mq == 'desktop',
          'u-mb--s u-mt--s u-width-100': _vm.$mq != 'desktop'
        },attrs:{"empty-title":_vm.model.selectDistrictText,"items":_vm.model.districts,"enable-empty-selection":true},model:{value:(_vm.store.state.selectedDistrict),callback:function ($$v) {_vm.$set(_vm.store.state, "selectedDistrict", $$v)},expression:"store.state.selectedDistrict"}}),_c('div',{staticClass:"c-farm-booking__venues-dropdown",class:{
          'u-width-100': _vm.$mq != 'desktop'
        }},[_c('Dropdown',{staticClass:"c-farm-booking__dropdown",class:{
            'u-width-100': _vm.$mq != 'desktop'
          },attrs:{"disabled":!_vm.store.state.selectedDistrict || _vm.store.state.loadingFarms,"empty-title":_vm.model.selectFarmText,"items":_vm.farms,"enable-empty-selection":true},model:{value:(_vm.store.state.selectedFarm),callback:function ($$v) {_vm.$set(_vm.store.state, "selectedFarm", $$v)},expression:"store.state.selectedFarm"}}),(_vm.store.state.loadingFarms)?_c('Loader',{staticClass:"c-farm-booking__venues-loader",attrs:{"color":"medium-gray","size":"small"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }