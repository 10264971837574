import storeFactory from '@/CVI/WebCore/lib/store-export-factory'
import webCoreApi from '@/CVI/WebCore/lib/api'
import api from '@/CVI/FarmBooking/lib/api'

const LS_FARM_ID = 'FarmBooking.FarmId'
const LS_DISTRICT_ID = 'FarmBooking.DistrictId'
const LS_FORM_SHOWN = 'FarmBooking.FormIsShown'

let loadCancelToken = null

const store = {
  state: {
    districts: [],
    farms: [],
    loadingFarms: false,
    isBookingFormShown: false,
    get selectedFarm() {
      const savedFarm = localStorage.getItem(LS_FARM_ID)
      const selectedFarm = savedFarm ? JSON.parse(savedFarm) : ''
      return selectedFarm
    },
    set selectedFarm(value) {
      localStorage.setItem(LS_FARM_ID, value ? JSON.stringify(value) : '')
    },
    get selectedDistrict() {
      const district = localStorage.getItem(LS_DISTRICT_ID)
      const selectedDistrict = district ? JSON.parse(district) : ''
      return selectedDistrict
    },
    set selectedDistrict(value) {
      localStorage.setItem(LS_DISTRICT_ID, value ? JSON.stringify(value) : '')
    },
    get showBookingAfterLogin() {
      return JSON.parse(localStorage.getItem(LS_FORM_SHOWN)) || false
    },
    set showBookingAfterLogin(value) {
      localStorage.setItem(LS_FORM_SHOWN, JSON.stringify(value))
    }
  },
  getters: {
    selectedFarmName(state) {
      const { selectedFarm, farms } = state
      return farms.find(farm => farm.id == selectedFarm).name
    },
    selectedDistrictName(state) {
      const { selectedDistrict, districts } = state
      return districts.find(farm => farm.id == selectedDistrict)?.name
    }
  },
  actions: {
    hideBookingForm({ state }) {
      state.isBookingFormShown = false
    },
    setDistricts({ state }, districts) {
      state.districts = districts
    },
    setShowBookingAfterLogin({ state }, value) {
      state.setShowBookingAfterLogin = value
    },
    setFarms({ state }, farms) {
      this.setBookingFormVisibility({ state }, false)
      state.farms = farms
    },
    setBookingFormVisibility({ state }, formVisibility) {
      state.isBookingFormShown = formVisibility
    },
    resetSelectedFarm({ state }) {
      state.selectedFarm = ''
    },
    resetDistrictFarmSelection({ state }) {
      state.selectedDistrict = ''
      this.resetSelectedFarm({ state })
      this.setShowBookingAfterLogin({ state }, false)
    },
    async loadFarms({ state }) {
      state.loadingFarms = true
      if (loadCancelToken) {
        loadCancelToken.cancel()
      }

      loadCancelToken = webCoreApi.createCancelToken()

      const { selectedDistrict } = state
      const districtId = state.districts.find(district => district.id == selectedDistrict).id
      const { data: farms } = await api.farms.get({
        cancelToken: loadCancelToken,
        districtId
      })
      this.setFarms({ state }, farms)
      state.loadingFarms = false
    }
  }
}

export default storeFactory.create(store)
