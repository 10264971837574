<template>
  <div class="c-checkbox">
    <input
      :id="id"
      v-model="model"
      class="c-checkbox__input"
      type="checkbox"
      :required="required"
    >
    <label
      :for="id"
      class="c-checkbox__label"
    >
      <slot />
    </label>
    <span class="c-checkbox-icon">
      <Icon
        :icon="iconCheck"
      />
    </span>
  </div>
</template>

<script>
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconCheck,
      model: this.value
    }
  },
  watch: {
    model(value) {
      this.$emit('input', value)
    }
  },
  computed: {
    id() {
      return `checkbox_${this._uid}`
    }
  }
}
</script>
