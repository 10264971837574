<template>
  <div class="u-width-720 u-ml--a u-mr--a u-flex u-flex-direction-column u-font-size-large">
    <template v-if="isBooked">
      <h2 class="u-font-alternative">
        {{ model.successfulRegistrationHeader }}
      </h2>
      <p v-html="confirmation" />
      <h3>{{ formattedDate }}</h3>
      <p class="u-m--0 u-mb--m">
        {{ farm.addressLine }} <br>
        {{ farm.postalCode }} {{ farm.city }}
      </p>
    </template>
    <template v-else>
      <h2 class="u-font-alternative">
        {{ model.failedRegistrationHeader }}
      </h2>
      <div v-html="model.failedRegistrationDescription"/>
    </template>
    <div v-html="model.contactInformation"></div>
    <div class="u-flex u-flex-direction-column@mobile u-mt--m">
      <button
        class="c-button c-button--dark"
        :class="{
          'u-mb--s': $mq != 'desktop'
        }"
        @click="onNewBookingClick"
      >
        <span class="c-button__text">
          {{ model.createNewBookingButtonText }}
        </span>
      </button>
      <a
        v-if="isBooked"
        class="c-button c-button--light u-ml--xs"
        :href="model.myBookingsLink.url"
        :target="model.myBookingsLink.openInNewWindow
          ? '_blank' : null"
        :rel="model.myBookingsLink.openInNewWindow
          ? 'noreferrer noopener' : null"
      >
        {{ model.myBookingsLink.text }}
      </a>
      <button
        v-else
        class="c-button c-button--light u-ml--xs"
        @click="onCancelClick"
      >
        {{ model.cancelButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import store from '@/CVI/FarmBooking/store'

export default {
  props: {
    farm: {
      type: Object,
      required: true
    },
    isBooked: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    bookingModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store
    }
  },
  computed: {
    confirmation() {
      const { confirmationEmailStatusMessage } = this.model
      const { email } = this.bookingModel?.contact
      return confirmationEmailStatusMessage.replace('{email}', email)
    },
    formattedDate() {
      const { date, time } = this.bookingModel
      return `${date}, ${time}`
    }
  },
  methods: {
    onNewBookingClick() {
      this.$emit('new-booking')
    },
    onCancelClick() {
      this.store.dispatch('setBookingFormVisibility')
      this.$emit('booking-cancel')
    }
  }
}
</script>
