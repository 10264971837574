<template>
  <div
    class="c-hero-block u-text-white c-hero-block__no-overflow u-flex u-flex-align-center c-hero-block__no-min-height c-hero-block__overlay-to-top"
  >
    <Picture
      class="c-hero-block__background"
      :src="model.backgroundImage"
      :resizer="model.imageResizer.name"
      :alt="model.backgroundImageAltText"
      :mobile="[375, 300]"
      :tablet="[768, 300]"
      :desktop="[992, 600]"
      :wide="[1920, 600]"
      :options="{ mode: 'crop'}"
    />
    <div
      class="o-content-box c-hero-block__content u-flex u-flex-direction-column u-flex-align-center u-width-100"
    >
      <h1 class="u-flex u-flex-direction-column u-font-size-h2 u-text-align--center">
        <span class="c-label--l u-text-no-letter-spacing u-mb--xxs">
          {{ model.kicker }}
        </span>
        <span class="u-font-alternative">
          {{ model.heading }}
        </span>
      </h1>
      <div
        class="u-flex u-text-black"
        :class="{
          'u-flex-direction-column': $mq != 'desktop'
        }"
      >
        <Dropdown
          v-model="store.state.selectedDistrict"
          class="c-farm-booking__dropdown "
          :class="{
            'u-mr--m': $mq == 'desktop',
            'u-mb--s u-mt--s u-width-100': $mq != 'desktop'
          }"
          :empty-title="model.selectDistrictText"
          :items="model.districts"
          :enable-empty-selection="true"
        />
        <div
          class="c-farm-booking__venues-dropdown"
          :class="{
            'u-width-100': $mq != 'desktop'
          }"
        >
          <Dropdown
            v-model="store.state.selectedFarm"
            class="c-farm-booking__dropdown"
            :class="{
              'u-width-100': $mq != 'desktop'
            }"
            :disabled="!store.state.selectedDistrict || store.state.loadingFarms"
            :empty-title="model.selectFarmText"
            :items="farms"
            :enable-empty-selection="true"
          />
          <Loader
            v-if="store.state.loadingFarms"
            class="c-farm-booking__venues-loader"
            color="medium-gray"
            size="small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import busIcon from '@ds/svg/icons/stroke/bus-school.svg'
import store from '@/CVI/FarmBooking/store'
import tracking from '@/CVI/FarmBooking/lib/tracking'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'


export default {
  components: {
    Picture,
    Loader,
    Dropdown
  },
  props: {
    model: {
      required: true,
      type: Object
    },
    userProfile: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      store
    }
  },
  computed: {
    farms() {
      const { farms } = this.store.state
      return farms.length
        ? farms.map(({ name, id, onlineBookable }) => {
          const farm = {
            name,
            id
          }
          if (onlineBookable) {
            farm.icon = {
              icon: busIcon,
              stroke: 'black',
              size: 24,
              description: 'bus'
            }
          }
          return farm
        })
        : []
    }
  },
  watch: {
    'store.state.selectedDistrict': {
      handler(value) {
        if (value) {
          this.store.dispatch('loadFarms')
          this.store.dispatch('resetSelectedFarm')

          const { selectedDistrictName } = this.store.getters
          tracking.selectDistrict(selectedDistrictName)
        } else {
          this.store.dispatch('resetSelectedFarm')
        }
      }
    },
    'store.state.selectedFarm': {
      handler(value) {
        if (value) {
          const { selectedFarmName } = this.store.getters
          tracking.selectFarm(selectedFarmName)
        }
      }
    }
  },
  mounted() {
    this.store.dispatch('setDistricts', this.model.districts)

    if (this.store.state.selectedDistrict) {
      this.store.dispatch('loadFarms')
    }
  },
  methods: {
    onBookingClick() {
      this.store.dispatch('showBookingForm')
    }
  }
}
</script>
