<template>
  <div>
    <Hero
      :model="model.hero"
      :user-profile="userProfile"
    />
    <div
      class="u-themeable-bg"
      :style="{
        '--bg-color': store.state.isBookingFormShown ? '#fff' : '#385F4E'
      }"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <FarmDetails
          v-if="store.state.selectedFarm && !store.state.isBookingFormShown"
          :model="model.farmInformation"
          :user-profile="userProfile"
        />
        <div
          v-if="!store.state.selectedFarm && !store.state.isBookingFormShown"
          v-start-with-html="initialContent"
          class="u-themeable-color-light"
          style="--bg-color: #385F4E"
        />
        <BookingForm
          v-if="store.state.isBookingFormShown"
          :model="model.bookingForm"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import store from '@/CVI/FarmBooking/store'
import Hero from '@/CVI/FarmBooking/components/Hero.vue'
import FarmDetails from '@/CVI/FarmBooking/components/FarmDetails.vue'
import BookingForm from '@/CVI/FarmBooking/components/BookingForm.vue'

export default {
  components: {
    BookingForm,
    Hero,
    FarmDetails
  },
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      store,
      initialContent: this.slotContent.trim()
    }
  },
  computed: {
    userProfile() {
      return this.model.bookingForm.contactSection.userProfile
    }
  },
  mounted() {
    if (this.store.state.setShowBookingAfterLogin) {
      this.store.dispatch('setBookingFormVisibility', true)
      this.store.dispatch('setShowBookingAfterLogin', false)
    }
  }
}
</script>
