<template>
  <div class="c-farm-booking__form o-content-box">
    <BookingConfirmation
      v-if="submitFormProccessed && !isLoading"
      :farm="farm"
      :is-booked="isBooked"
      :model="model.bookingConfirmation"
      :booking-model="bookingModel"
      @booking-cancel="onBookingCancel"
      @new-booking="onNewBooking"
    />
    <div
      v-else
      class="u-width-720 u-ml--a u-mr--a u-flex u-flex-direction-column"
    >
      <h3 class="u-font-alternative">
        {{ title }}
      </h3>
      <p
        v-if="model.description"
        class="u-font-size-large u-m--0 u-mb--m"
      >
        {{ model.description }}
      </p>
      <form
        novalidate
        @submit.stop="submitForm"
      >
        <div class="u-mb--l">
          <h4>
            {{ model.dateAndTimeLabelText }}
          </h4>
          <div class="u-mb--s">
            <DatePicker
              v-model="date"
              :disabled="isDatepickerDisabled"
              prefix-class="c"
              format="DD MMM YYYY"
              :placeholder="model.datePlaceholder"
              :input-class="[
                'c-text-input',
                (isSubmitted || $v.date.$dirty) && $v.date.$invalid ? 'c-text-input--error' : ''
              ]"
              :disabled-date="disabledDates"
            >
              <template v-slot:icon-calendar>
                <Icon
                  :icon="calendarIcon"
                  :size="16"
                  fill="black"
                />
              </template>
            </DatePicker>
            <FormErrorLine
              v-if="(isSubmitted || $v.date.$dirty) && $v.date.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="c-farm-booking__form-date-time">
            <Dropdown
              v-model="time"

              :empty-title="model.timePlaceholder"
              :items="timeslots"
              :disabled="!timeslots.length"
            />
            <FormErrorLine
              v-if="(isSubmitted || $v.time.$dirty) && $v.time.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <span
            v-if="isDatepickerDisabled"
            class="u-text-error u-flex u-mt--s"
          >
            {{ noFreeSlotsMessage }}
          </span>
        </div>

        <div class="u-mb--l">
          <h4 class="u-mb--m">
            {{ model.contactSection.title }}
          </h4>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.contactSection.firstNamePlaceholder }}</label>
            <input
              v-model="$v.firstName.$model"
              type="text"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.firstName.$dirty) && $v.firstName.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.firstName.$dirty) && $v.firstName.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.contactSection.lastNamePlaceholder }}</label>
            <input
              v-model="$v.lastName.$model"
              type="text"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.lastName.$dirty) && $v.lastName.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.lastName.$dirty) && $v.lastName.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.contactSection.emailPlaceholder }}</label>
            <input
              :value="email"
              type="text"
              class="c-text-input"
              disabled
            >
          </div>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.contactSection.phoneNumberPlaceholder }}</label>
            <input
              v-model="$v.phoneNumber.$model"
              type="tel"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.phoneNumber.$dirty) && $v.phoneNumber.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.phoneNumber.$dirty) && $v.phoneNumber.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
        </div>

        <div class="u-mb--l">
          <h4 class="u-mb--m">
            {{ model.schoolSection.title }}
          </h4>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.schoolSection.namePlaceholder }}</label>
            <input
              v-model="$v.schoolName.$model"
              type="text"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.schoolName.$dirty) && $v.schoolName.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.schoolName.$dirty) && $v.schoolName.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.schoolSection.addressPlaceholder }}</label>
            <input
              v-model="$v.schoolAddress.$model"
              type="text"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.schoolAddress.$dirty) && $v.schoolAddress.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.schoolAddress.$dirty) && $v.schoolAddress.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-width-50 u-mb--s">
            <label class="c-label--m u-mb--xs">{{ model.schoolSection.postalCodePlaceholder }}</label>
            <input
              v-model="$v.postalCode.$model"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.postalCode.$dirty) && $v.postalCode.$invalid,
                'u-width-30': $mq == 'desktop',
                'u-width-50': $mq != 'desktop'
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.postalCode.$dirty) && $v.postalCode.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column">
            <label class="c-label--m u-mb--xs">{{ model.schoolSection.cityPlaceholder }}</label>
            <input
              v-model="$v.city.$model"
              type="text"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.city.$dirty) && $v.city.$invalid
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.city.$dirty) && $v.city.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
        </div>

        <div class="u-mb--m">
          <h4 class="u-mb--m">
            {{ model.bookingSection.title }}
          </h4>
          <div class="u-flex u-flex-direction-column u-width-50 u-mb--m">
            <label class="c-label--m u-mb--xs">{{ model.bookingSection.numberOfParticipantsPlaceholder }}</label>
            <input
              v-model.number="$v.numberOfParticipant.$model"
              type="text"
              class="c-text-input"
              inputmode="numeric"
              pattern="[0-9]*"
              :class="{
                'c-text-input--error': (isSubmitted || $v.numberOfParticipant.$dirty) && $v.numberOfParticipant.$invalid,
                'u-width-30': $mq == 'desktop',
                'u-width-60': $mq != 'desktop'
              }"
            >
            <span
              v-if="amountOfParticipants"
              class="u-mt--xs u-ml--xs"
            >
              {{ amountOfParticipants }}
            </span>
            <FormErrorLine
              v-if="(isSubmitted || $v.numberOfParticipant.$dirty) && $v.numberOfParticipant.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-width-50 u-mb--m">
            <label class="c-label--m u-mb--xs">{{ model.bookingSection.numberOfTeachersPlaceholder }}</label>
            <input
              v-model.number="$v.numberOfTeachers.$model"
              type="text"
              class="c-text-input"
              inputmode="numeric"
              pattern="[0-9]*"
              :class="{
                'c-text-input--error': (isSubmitted || $v.numberOfTeachers.$dirty) && $v.numberOfTeachers.$invalid,
                'u-width-30': $mq == 'desktop',
                'u-width-60': $mq != 'desktop'
              }"
            >
            <FormErrorLine
              v-if="(isSubmitted || $v.numberOfTeachers.$dirty) && $v.numberOfTeachers.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-width-50 u-mb--m">
            <label class="c-label--m u-mb--xs">{{ model.bookingSection.gradePlaceholder }}</label>
            <input
              v-model.number="$v.grade.$model"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              class="c-text-input"
              :class="{
                'c-text-input--error': (isSubmitted || $v.grade.$dirty) && $v.grade.$invalid,
                'u-width-30': $mq == 'desktop',
                'u-width-60': $mq != 'desktop'
              }"
            >
            <span
              v-if="model.bookingSection.gradeHelpText"
              class="u-mt--xs u-ml--xs"
            >
              {{ gradeHelp }}
            </span>
            <FormErrorLine
              v-if="(isSubmitted || $v.grade.$dirty) && $v.grade.$invalid"
              class="u-mt--xs"
              :text="model.validationErrorMessage"
            />
          </div>
          <div class="u-flex u-flex-direction-column u-mb--m">
            <label class="c-label--m u-mb--xs">{{ model.bookingSection.classNamePlaceholder }}</label>
            <input
              v-model="className"
              type="text"
              class="c-text-input"
            >
          </div>
          <div class="u-flex u-flex-direction-column u-mb--m">
            <label class="c-label--m u-mb--xs">{{ model.bookingSection.messagePlaceholder }}</label>
            <textarea
              v-model="message"
              type="text"
              class="c-text-input c-farm-booking__form-textarea u-max-width-100"
            />
          </div>
        </div>

        <div class="u-flex u-flex-direction-column">
          <div class="u-mb--s">
            <Checkbox
              v-if="isAcceptTermsVisible"
              v-model="acceptTerms"
              :required="true"
            >
              {{ model.acceptTermsCheckbox.text }}
              <a
                :href="model.acceptTermsCheckbox.link.url"
                :target="model.acceptTermsCheckbox.link.openInNewWindow ? '_blank' : null"
                :rel="model.acceptTermsCheckbox.link.openInNewWindow
                  ? 'noreferrer noopener' : null"
                class="u-text-underline u-ml--xxs"
              >
                {{ model.acceptTermsCheckbox.link.text }}
              </a>
            </Checkbox>
          </div>
          <Checkbox
            v-if="isAcceptNewsletterVisible"
            v-model="acceptNewsletterSubscription"
            class="u-mb--l"
            :required="true"
          >
            {{ model.newsletterSubscribeCheckbox.text }}
            <a
              :href="model.newsletterSubscribeCheckbox.link.url"
              :target="model.newsletterSubscribeCheckbox.link.openInNewWindow
                ? '_blank' : null"
              :rel="model.newsletterSubscribeCheckbox.link.openInNewWindow
                ? 'noreferrer noopener' : null"
              class="u-text-underline u-ml--xxs"
            >
              {{ model.newsletterSubscribeCheckbox.link.text }}
            </a>
          </Checkbox>
        </div>
        <button
          class="c-button c-button--dark"
          :class="{
            'is-loading': isLoading
          }"
          :disabled="isAcceptTermsVisible && !acceptTerms"
        >
          <span class="c-button__text">
            {{ model.bookButtonText }}
          </span>
          <Loader
            v-if="isLoading"
            size="small"
            class="c-button__loader"
            color="medium-gray"
          />
        </button>
        <button
          class="c-button c-button--light u-ml--xs"
          @click="returnToFarmDetails"
        >
          {{ model.cancelButtonText }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import calendarIcon from '@ds/svg/icons/stroke/calendar.svg'
import {
  numeric, required, minLength, maxLength, minValue, maxValue
} from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/sv'
import store from '@/CVI/FarmBooking/store'
import Checkbox from '@/CVI/WebCore/components/CheckboxWithLabel.vue'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import api from '@/CVI/FarmBooking/lib/api'
import webCoreApi from '@/CVI/WebCore/lib/api'
import BookingConfirmation from '@/CVI/FarmBooking/components/BookingConfirmation.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracking from '@/CVI/FarmBooking/lib/tracking'

export default {
  components: {
    Checkbox,
    BookingConfirmation,
    Dropdown,
    DatePicker,
    FormErrorLine,
    Icon,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    const {
      userProfile: {
        email,
        firstName,
        lastName,
        phone: phoneNumber
      }
    } = this.model.contactSection

    const { isEnabled: isAcceptTermsVisible } = this.model.acceptTermsCheckbox
    const { isEnabled: isAcceptNewsletterVisible } = this.model.newsletterSubscribeCheckbox
    return {
      calendarIcon,
      store,
      gradeMin: 1,
      gradeMax: 3,
      isSubmitted: false,
      isLoading: false,
      cancelToken: null,
      isBooked: false,
      confirmedBooking: false,
      availableDates: {},
      date: '',
      time: '',
      firstName,
      lastName,
      email,
      phoneNumber,
      schoolName: '',
      schoolAddress: '',
      city: '',
      postalCode: '',
      numberOfParticipant: '',
      numberOfTeachers: '',
      grade: '',
      className: '',
      message: '',
      acceptTerms: false,
      acceptNewsletterSubscription: true,
      submitFormProccessed: false,
      bookingModel: null,
      isAcceptTermsVisible,
      isAcceptNewsletterVisible,
      isDatepickerDisabled: false
    }
  },
  computed: {
    farm() {
      const { selectedFarm, farms } = this.store.state
      return farms.find(({ id }) => id == selectedFarm)
    },
    title() {
      const { name } = this.farm
      return this.model.title.replace('{farmName}', name)
    },
    timeslots() {
      const { date, availableDates } = this
      const tmp = new Date(date).getTime()
      if (availableDates[tmp]) {
        return availableDates[tmp]
      }
      return []
    },
    amountOfParticipants() {
      const {
        farm: {
          bookingInfo: { minParticipants, maxParticipants }
        }
      } = this
      const { numberOfParticipantsHelpText } = this.model.bookingSection
      if (maxParticipants && numberOfParticipantsHelpText) {
        return numberOfParticipantsHelpText.replace('{range}', `${minParticipants} - ${maxParticipants}`)
      }
      return ''
    },
    gradeHelp() {
      const { gradeHelpText } = this.model.bookingSection
      const { gradeMin, gradeMax } = this
      return gradeHelpText.replace('{range}', `${gradeMin}-${gradeMax}`)
    },
    noFreeSlotsMessage() {
      const { noFreeTimeslotsMessageText } = this.model
      return noFreeTimeslotsMessageText?.replace('{time}', 90)
    }
  },
  watch: {
    'store.state.selectedFarm': {
      handler() {
        this.availableDates = {}
        this.date = ''
        this.getTimeslots()
      }
    }
  },
  validations() {
    let numberOfParticipant = {
      required,
      numeric
    }
    const {
      farm: {
        bookingInfo: { minParticipants, maxParticipants }
      }
    } = this

    if (minParticipants && maxParticipants) {
      numberOfParticipant = {
        required,
        numeric,
        minValue: minValue(minParticipants),
        maxValue: maxValue(maxParticipants)
      }
    }

    return {

      date: {
        required
      },
      time: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required
      },
      phoneNumber: {
        required
      },
      schoolName: {
        required
      },
      schoolAddress: {
        required
      },
      postalCode: {
        required,
        numeric,
        maxLength: maxLength(5),
        minLength: minLength(5)
      },
      city: {
        required
      },
      numberOfTeachers: {
        required,
        numeric
      },
      numberOfParticipant,
      grade: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(3)
      }
    }
  },
  mounted() {
    this.getTimeslots()
  },
  methods: {
    returnToFarmDetails() {
      this.store.dispatch('setBookingFormVisibility', false)
    },
    async submitForm(e) {
      e.preventDefault()
      this.isSubmitted = true
      if (this.$v.$invalid || (this.isAcceptTermsVisible && !this.acceptTerms)) {
        return
      }
      this.isLoading = true
      tracking.submitBookingForm()

      const {
        farm: { id: farmId },
        firstName,
        lastName,
        phoneNumber,
        email,
        date,
        time,
        schoolName,
        schoolAddress,
        postalCode,
        city,
        numberOfParticipant,
        numberOfTeachers,
        grade,
        className,
        message,
        acceptTerms,
        acceptNewsletterSubscription
      } = this

      try {
        this.submitFormProccessed = true
        const dateObj = new Date(date)
        const formattedDate = this
          .formatDate(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate())

        const { data } = await api.farms.bookings({
          farmId,
          firstName,
          lastName,
          phoneNumber,
          email,
          date: formattedDate,
          time,
          schoolName,
          schoolAddress,
          postalCode,
          city,
          numberOfParticipant,
          numberOfTeachers,
          grade,
          className,
          message,
          acceptTerms,
          acceptNewsletterSubscription
        })
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })

        this.bookingModel = data
        tracking.submitBookingFormSuccess()
        this.$nextTick(() => {
          this.isBooked = true
        })
      } catch (err) {
        this.submitError = false
      }

      this.isLoading = false
    },
    formatDate(year, month, day) {
      const formattedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1
      const formattedDay = day < 10 ? `0${day}` : day
      return `${year}-${formattedMonth}-${formattedDay}`
    },
    async getTimeslots() {
      let { cancelToken } = this
      const { availableDates } = this
      const { daysBeforeCloseBooking } = this.model

      if (cancelToken) {
        cancelToken.cancel()
      }

      cancelToken = webCoreApi.createCancelToken()
      const { selectedFarm: farmId } = this.store.state
      const today = daysBeforeCloseBooking ? new Date(Date.now() + daysBeforeCloseBooking * 24 * 3600 * 1000) : new Date()
      const year = today.getFullYear()
      const day = today.getDate()
      const month = today.getMonth()
      const nextDate = new Date(today.getTime() + 90 * 24 * 3600 * 1000) // 3 month from now


      const fromDate = this.formatDate(year, month, day)
      const toDate = this.formatDate(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())

      const { data } = await api.farms.getTimeslots({
        farmId,
        fromDate,
        toDate,
        cancelToken
      })
      if (data.length == 0) {
        this.isDatepickerDisabled = true
        this.showDisabledDateMessage = true
      } else {
        this.isDatepickerDisabled = false
        this.showDisabledDateMessage = false
        data.forEach((slot) => {
          const date = new Date(slot.date).setHours(0, 0, 0, 0)
          if (!availableDates[date]) {
            availableDates[date] = [{
              name: slot.time,
              id: slot.time
            }]
          } else {
            availableDates[date].push({
              name: slot.time,
              id: slot.time
            })
          }
        })
      }

    },
    disabledDates(date) {
      const { availableDates } = this
      const convertedDate = new Date(date).setHours(0, 0, 0, 0)
      return date < new Date(new Date().setHours(0, 0, 0, 0)) || !availableDates[convertedDate]
    },
    onNewBooking() {
      this.bookingModel = null
      this.submitFormProccessed = false
      this.isBooked = false
      tracking.createNewBooking()
    },
    onBookingCancel() {
      tracking.cancelBookingForm()
      this.isBooked = false
    }
  }
}
</script>
