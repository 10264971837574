<template>
  <div
    class="o-content-box u-themeable-bg u-themeable-color-light"
    style="--bg-color: #385F4E"
  >
    <div class="u-width-720 u-ml--a u-mr--a u-flex u-flex-direction-column">
      <template v-if="farm">
        <div class="u-flex u-flex-align-start">
          <h2 class="u-font-alternative u-mr--s">
            {{ farm.name }}
          </h2>
          <button
            class="c-button-close c-button-close--small u-ml--a"
            @click="resetFarms"
          >
            <Icon
              :size="24"
              :icon="iconClose"
              stroke="white"
            />
          </button>
        </div>
        <div class="c-rich-text u-font-size-xlarge">
          <h4>{{ model.descriptionLabelText }}</h4>
          <p v-html="farm.description" />
          <h4>{{ model.addressLabelText }}</h4>
          <p>
            {{ farm.addressLine }} <br>
            {{ farm.postalCode }} {{ farm.city }}
          </p>
          <template v-if="farm.bookingInfo && farm.bookingInfo.busComments">
            <h4>{{ model.busInformationLabelText }}</h4>
            <p>{{ farm.bookingInfo.busComments }}</p>
          </template>
          <template v-if="farm.phone || farm.email">
            <h4>{{ model.contactInformationLabelText }}</h4>
            <p>
              <strong v-if="farm.phone">{{ model.phoneLabelText }}:</strong> {{ farm.phone }}
              <br>
              <strong v-if="farm.email">{{ model.emailLabelText }}:</strong> {{ farm.email }}
            </p>
          </template>
          <div
            v-if="farm.onlineBookable"
            class="u-flex u-flex-wrap"
          >
            <button
              class="c-button c-button--dark-inverted u-themeable-color u-mr--s u-mb--xs"
              @click="onBookingClick"
            >
              {{ model.bookFarmLinkText }}
            </button>
            <a
              class="c-button c-button--light-inverted u-themeable-color-light u-mb--xs"
              :href="model.myBookingsLink.url"
            >
              {{ model.myBookingsLink.text }}
            </a>
          </div>
          <a
            v-if="farm.email && !farm.onlineBookable"
            class="c-button c-button--dark-inverted"
            :href="`mailto:${farm.email}`"
          >
            {{ model.sendEmailLinkText }}
          </a>
        </div>
      </template>
      <template v-else>
        <Loader
          color="medium-gray"
          size="small"
        />
      </template>
    </div>
  </div>
</template>

<script>
import iconClose from '@ds/svg/icons/stroke/close.svg'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import store from '@/CVI/FarmBooking/store'
import tracking from '@/CVI/FarmBooking/lib/tracking'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Loader,
    Icon
  },
  props: {
    model: {
      required: true,
      type: Object
    },
    userProfile: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      store,
      iconClose
    }
  },
  computed: {
    pictureContainerClass() {
      return [
        'c-trans-teaser__picture-container',
        'o-content-box',
        'u-flex u-flex-justify-center u-flex-align-center u-flex-shrink-0'
      ]
    },
    contentBoxClass() {
      return [
        'c-trans-teaser__text',
        'o-content-box u-flex u-flex-shrink-0'
      ]
    },
    farm() {
      const { selectedFarm, farms } = this.store.state
      return farms.find(({ id }) => id == selectedFarm)
    }
  },
  mounted() {
    if (!this.farm) {
      this.store.dispatch('loadFarms')
    }
  },
  methods: {
    onBookingClick() {
      const selectedFarmName = this.farm.name
      tracking.openBookingForm(selectedFarmName)
      if (this.userProfile) {
        this.store.dispatch('setBookingFormVisibility', true)
      } else {
        this.store.dispatch('setShowBookingAfterLogin', true)
        window.location.hash = '#login'
      }
    },
    resetFarms() {
      this.store.dispatch('resetDistrictFarmSelection')
    }
  }
}
</script>
